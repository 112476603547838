import React from 'react';
import { navigate } from 'gatsby';

import { Box } from '@core';
import { Loader } from '@components';
import { navigateToAnnouncement } from '@shared/navigat-helper';

const Checkout = () => {
  navigateToAnnouncement();
  return (
    <Box width="100vw" height="100vh" display="flex">
      <Box margin="auto">
        <Loader isLoading />
      </Box>
    </Box>
  );
};

export default Checkout;
